import React from "react";
import Navbar from "./Navbar";

import About from "./About";
import Team from "./Team";
import Footer from "./Footer";

export default function AboutPage() {
  return (
    <>
      <Navbar />
      <About />
      <Team />
      <Footer />
    </>
  );
}
