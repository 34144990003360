import React from "react";
import { Link } from "react-router-dom";

export default function Features() {
  return (
    <section id="features" className="ud-features">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title">
              <span>Features</span>
              <h2>Main Features of TopCx.ai</h2>
              {/* <p>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p> */}
            </div>
          </div>
        </div>
        <div className="feature-flex" style={{ flexWrap: "wrap" }}>
          <div className="col-xl-2 col-lg-2 col-sm-6">
            <div
              className="ud-single-feature wow fadeInUp"
              data-wow-delay=".1s"
            >
              <div className="ud-feature-icon">
                <i className="lni lni-gift"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">TopX Resolutions</h3>
                <p className="ud-feature-desc">
                  Find the most relevant resolutions for your ticket based on
                  similar cases handled by your organization. Got more details?
                  No problem! Re-run TopX to get updated resolutions using the
                  latest ticket information, ensuring you always have the best
                  solutions at your fingertips.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-sm-6">
            <div
              className="ud-single-feature wow fadeInUp"
              data-wow-delay=".15s"
            >
              <div className="ud-feature-icon">
                <i className="lni lni-move"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">SearchX</h3>
                <p className="ud-feature-desc">
                  Easily search through a vast database of cases handled by your
                  organization. Use advanced filters on categories and details
                  to find the exact type of ticket you need. This powerful
                  search feature helps you quickly locate the most relevant
                  cases, saving you time and improving your support efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-sm-6">
            <div
              className="ud-single-feature wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="ud-feature-icon">
                <i className="lni lni-layout"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">BotX</h3>
                <p className="ud-feature-desc">
                  Quickly get intent-specific answers from your organization's
                  knowledge base, including FAQs, help center, and product
                  catalog. Our AI-powered chatbot summarizes this information
                  into clear, conversational responses, making your support
                  experience faster and more effective.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-sm-6">
            <div
              className="ud-single-feature wow fadeInUp"
              data-wow-delay=".25s"
            >
              <div className="ud-feature-icon">
                <i className="lni lni-layers"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">XAnalytics</h3>
                <p className="ud-feature-desc">
                  Explore a unified dashboard showcasing detailed agent
                  performance metrics such as ticket handling behavior, activity
                  levels, response times, solution and service quality. Assess
                  agent performance within their groups, teams, or ticket
                  categories across any timeframe up to the past year. Dive deep
                  into individual agent contributions, examining every ticket
                  they have resolved for comprehensive insights.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-sm-6">
            <div
              className="ud-single-feature wow fadeInUp"
              data-wow-delay=".25s"
            >
              <div className="ud-feature-icon">
                <i className="lni lni-layers"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">SurveyX</h3>
                <p className="ud-feature-desc">
                  Engage customers by sending emails to request feedback on
                  their support experience, including overall quality, agent
                  responsiveness, expertise, and behavior. Utilize this valuable
                  feedback to gain insights into agent behavior and enhance
                  overall support performance.
                </p>
                <Link className="ud-feature-link">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
