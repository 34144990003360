import React from "react";
import about from "../images/about/about-image.svg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <section id="about" className="ud-about">
        <div className="container">
          <div className="ud-about-wrapper wow fadeInUp" data-wow-delay=".2s">
            <div className="ud-about-content-wrapper">
              <div className="ud-about-content">
                <span className="tag">About Us</span>
                <h2>About TopCx.ai</h2>
                <p>
                  At TopCx.ai, we empower your hybrid workforce to be more
                  efficient, engaged, and productive, helping them exceed their
                  own performance expectations. We are a dynamic India-based
                  startup where innovation meets customer support management.
                  Our all-in-one platform empowers large teams to excel in the
                  fast-paced digital era.
                </p>
                {/* <Link className="ud-main-btn">Learn More</Link> */}
                <p>
                  Join us at TopCx.ai and transform your customer support
                  operations with our innovative, AI-powered solutions. We are
                  committed to helping you create exceptional customer
                  experiences and achieve organizational success.
                </p>
              </div>
            </div>
            <div className="ud-about-image">
              <img src={about} alt="about" />
            </div>
          </div>
        </div>
      </section>
      <section className="ud-team">
        <div className="container-xxl">
          <div className="feature-flex">
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div
                className="ud-single-feature wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="ud-feature-icon">
                  <i className="lni lni-layout"></i>
                </div>
                <div className="ud-feature-content">
                  <h3 className="ud-feature-title">
                    {" "}
                    What is the TopCX Agent Module and how does it help support
                    agents?
                  </h3>
                  <p className="ud-feature-desc">
                    The TopCX Agent Module is an AI-powered tool designed to
                    enhance the efficiency and effectiveness of customer support
                    agents. It helps agents by providing quick access to
                    relevant resolutions, answers from the knowledge base, and
                    professional email drafts. Additionally, the module offers
                    advanced search capabilities and detailed performance
                    metrics, enabling agents to resolve queries faster, improve
                    their skills, and deliver exceptional customer experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div
                className="ud-single-feature wow fadeInUp"
                data-wow-delay=".1s"
              >
                <div className="ud-feature-icon">
                  <i className="lni lni-gift"></i>
                </div>
                <div className="ud-feature-content">
                  <h3 className="ud-feature-title">
                    {" "}
                    What is the purpose of SurveyX?
                  </h3>
                  <p className="ud-feature-desc">
                    SurveyX captures customer feedback after ticket resolution
                    to evaluate and improve support performance. By assessing
                    the effectiveness and efficiency of the support provided, it
                    identifies areas for improvement and enhances overall
                    customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div
                className="ud-single-feature wow fadeInUp"
                data-wow-delay=".15s"
              >
                <div className="ud-feature-icon">
                  <i className="lni lni-move"></i>
                </div>
                <div className="ud-feature-content">
                  <h3 className="ud-feature-title">
                    How can XAnalytics benefit support leaders?
                  </h3>
                  <p className="ud-feature-desc">
                    XAnalytics benefits support leaders by providing actionable
                    insights and detailed analysis of support operations. It
                    offers comprehensive data on various metrics, enabling
                    leaders to identify trends, pinpoint areas for improvement,
                    and make data-driven decisions. The dashboard helps analyze
                    the performance of individual agents and the support team,
                    recognize top performers, address training needs, and
                    optimize resource allocation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div
                className="ud-single-feature wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="ud-feature-icon">
                  <i className="lni lni-layout"></i>
                </div>
                <div className="ud-feature-content">
                  <h3 className="ud-feature-title">
                    {" "}
                    What is the BotX and how does it work?
                  </h3>
                  <p className="ud-feature-desc">
                    BotX allows agents to ask questions directly related to
                    their current tickets. Using generative AI, BotX processes
                    the company’s knowledge base—including PDF/Word documents,
                    text files, websites, and other document forms—to provide
                    accurate and contextually relevant answers. This ensures
                    agents have access to comprehensive information, improving
                    the quality of their responses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
