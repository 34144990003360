import React from "react";
import Navbar from "./Navbar";
import Pricing from "./Pricing";
import Faq from "./Faq";
import Footer from "./Footer";

export default function PricingPage() {
  return (
    <>
      <Navbar />
      <Pricing />
      {/* <Faq /> */}
      <Footer />
    </>
  );
}
